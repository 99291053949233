<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" data-projection-id="1" style="opacity: 1">
        <div data-testid="hero-video-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <div class="relative w-full h-full overflow-hidden max-h-[80vh] h-[520px] md:h-[650px] md:max-h-full w-full">
            <div autoplay="" style="width: 100%; height: 100%">
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b1.png')" alt="" />
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-300 dark:text-iblack-300 max-w-[500px]">
                2022 Year in Review
              </h1>
              <p class="text-lg text-iblack-300 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                Building for the Future
              </p>
              <div class="items-left justify-left mt-8">
                <a
                  draggable="false"
                  target="_self"
                  class="inline-flex items-center button-target transition-opacity duration-400 opacity-100 hover:opacity-80"
                  href="/growth/2022-year-in-review"
                  ><span
                    ><svg
                      class="overflow-visible"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        data-testid="bg-circle"
                        cx="17"
                        cy="17"
                        r="17"
                        fill="#F0F0F0"
                        stroke="#FF0000"
                        stroke-width="0"
                      ></circle>
                      <g class="scale-up" clip-path="url(#a)">
                        <path
                          data-testid="stroke-color-path"
                          d="M17.9485 21.5956 22.5441 17l-4.5956-4.5956M22.5441 17H11.5146h11.0295Z"
                          stroke="#4DB96B"
                          stroke-width="1.29983"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path
                            data-testid="fill-bg-path"
                            fill="#F0F0F0"
                            transform="rotate(-45 23.52081528 1.25735931)"
                            d="M0 0h15.598v15.598H0z"
                          ></path>
                        </clipPath>
                      </defs></svg
                  ></span>
                  <p class="text-iblack-700 dark:text-iwhite-100 md:text-iblack-700 dark:text-iwhite-100"></p
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              data-projection-id="2"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/companies"
                    ><section class="" data-projection-id="3">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="4"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b2.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            ZEN CAPITAL Growth Companies
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="6"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Our portfolio of category defining businesses drive global impact and change.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/visionaries"
                    ><section class="" data-projection-id="7">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="8"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="9"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            The Architects Creating the Future
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="10"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Our vibrant ecosystem of founders, pioneers, and business leaders are building for the long
                            term.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="main-grid mb-30">
        <a
          draggable="false"
          target="_self"
          class="main-grid--in-grid transition-opacity duration-500 hover:opacity-80 focus:opacity-80"
          href="/growth/updates"
          ><div
            class="relative transition-opacity duration-300 overflow-hidden"
            data-projection-id="12"
            style="  transform: translateY(40px) translateZ(0px)"
          >
            <div
              data-testid="tout-video-bg"
              class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
            >
              <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
                <div autoplay="" style="width: 100%; height: 100%">
                  <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b4.png')" alt="" />
                </div>
              </div>
            </div>
            <div
              class="md:h-[400px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[400px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
            >
              <div
                class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
              >
                <div
                  class="mt-[30px] md:my-0"
                  data-projection-id="13"
                  style="  transform: translateY(20px) translateZ(0px)"
                >
                  <h1 class="text-2xl text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-700">
                    Frank Slootman’s Urgent Mission
                  </h1>
                  <p class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-600 mt-2.5 max-w-[500px]">
                    Snowflake’s CEO reveals his key to accelerating performance.
                  </p>
                </div>
              </div>
            </div>
          </div></a
        >
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="" data-projection-id="14">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="15"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b5.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="16"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Global Expansion for ZEN CAPITAL Growth
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="17"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Meet our European leadership in a conversation between Matt Jacobson and Seth Pierrepont.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="" data-projection-id="18">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="19"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b6.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="20"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            A New Approach to Performance Management
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="21"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Redefining performance management in a distributed world.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="22"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="" data-projection-id="23">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="24"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b7.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="25"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            The ZEN CAPITAL Growth Enterprise Five
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="26"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Key performance indicators of Enterprise SaaS companies
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights"
                    ><section class="" data-projection-id="27">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="28"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b8.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="29"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            2022 Topline Growth and Efficiency
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="30"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            The data behind scaling a B2B SaaS business and what strength looks like at all stages of
                            growth
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="31"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="" data-projection-id="32">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="33"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b9.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="34"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Virtru
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="35"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            With data owners today having a better sense than ever of how theirs is being used, Virtru
                            defines zero trust data access.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="" data-projection-id="36">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="37"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b10.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="38"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            CaptivateIQ
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="39"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Meeting the engine for modern sales compensation management.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates"
                    ><section class="" data-projection-id="40">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="41"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b11.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="42"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            1Password
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="43"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Defining the future of human-centric security.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="44"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="javascript:void(0)"
                    ><section class="" data-projection-id="45">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="46"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b12.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div></section
                  ></a>
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="javascript:void(0)"
                    ><section class="" data-projection-id="47">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="48"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b13.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div></section
                  ></a>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="javascript:void(0)"
                    ><section class="" data-projection-id="49">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="50"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/growth/b14.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4"><div class="col-span-6"></div></div></section
                  ></a>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
